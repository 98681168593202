import React from 'react'
import {useMedia} from 'use-media'

// Components
import GeneralNavigation, {NavItem} from 'los-design-system/components/Navigation/GeneralNavigation'
import GeneralResponsiveNavigation from 'los-design-system/components/Navigation/GeneralResponsiveNavigation'

const Navigation = () => {
  const isWide = useMedia({minWidth: 768})

  const navItems = (
    <>
      <NavItem>
        <a href="https://lifeonscreen.com/about-us">About us</a>
      </NavItem>
      <NavItem>
        <a href="https://lifeonscreen.com/listings">See all locations</a>
      </NavItem>
    </>
  )

  const responsiveNavItems = (
    <>
      <div>
        <a href="https://lifeonscreen.com/about-us">About us</a>
      </div>
      <div>
        <a href="https://lifeonscreen.com/listings">See all locations</a>
      </div>
    </>
  )

  return isWide ? (
    <GeneralNavigation navItems={navItems} />
  ) : (
    <GeneralResponsiveNavigation
      openMenuItems={responsiveNavItems}
      responsiveHeader={<div />}
      search={<div />}
    />
  )
}

export default Navigation
