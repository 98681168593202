import React from 'react'
import styled, {ThemeProvider} from 'styled-components'
import {Link, graphql} from 'gatsby'
import {display, DisplayProps} from 'styled-system'
import Box from 'los-design-system/components/Box'
import Flex from 'los-design-system/components/Flex'
import Grid from 'los-design-system/components/Grid'
import {Text, Title} from 'los-design-system/components/Text'
import Author from 'los-design-system/components/Author'
import FormattedDate from 'los-design-system/components/FormattedDate'
import Container from 'los-design-system/components/Container'
import Article from 'los-design-system/components/Article'
import ArticleSocialShare from 'los-design-system/components/ArticleSocialShare'
import ArticleContainer from 'los-design-system/components/ArticleContainer'
import PreloadImage from 'los-design-system/components/PreloadImage'
import SectionTitleSubtitle from 'los-design-system/components/SectionTitleSubtitle'
import FeaturedPost from 'los-design-system/components/FeaturedPost'
import ArrowIcon from 'los-design-system/components/icons/ArrowIcon'
import Footer from 'los-design-system/components/Footer'
import ResetCss from 'los-design-system/components/reset'
import CalendarIcon from 'los-design-system/components/icons/CalendarIcon'
import dotsSvg from 'los-design-system/components/icons/assets/dots.svg'
import config from '../../config'

// Components and theme
import theme from '../theme'
import Navigation from '../components/Navigation'
import Subscribe from '../components/Subscribe'
import Seo from '../components/Seo'

const ImgWrapper = styled('div')<DisplayProps>`
  width: 100%;
  position: relative;
  ${display}
`

const BackA = styled(Link)`
  color: currentColor;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Dots = styled('div')`
  width: 100%;
  height: 80px;
  background-size: 9px 18px;
  background-image: url(${dotsSvg});
`

const StyledArticle = styled(Article)`
  margin-top: 10px;
  ul {
    li {
      &:before {
        content: '●';
        margin-right: 17px;
      }
      display: flex;
      list-style: disc outside none;
      white-space: pre-wrap;
      p {
        margin-left: 0;
      }
    }
  }
  img {
    margin-top: 0;
  }
  figcaption {
    text-align: center;
  }
`

interface BlogPostProps {
  data: {
    markdownRemark: {
      frontmatter: {
        author: string
        avatar: string
        coverImage: string
        description: string
        title: string
        published: string
      }
      fields: {
        slug: string
      }
      html: string
    }
    allMarkdownRemark: {
      edges: [
        {
          node: {
            frontmatter: {
              author: string
              avatar: string
              coverImage: string
              published: string
              tag: string
              title: string
            }
            fields: {
              slug: string
            }
          }
        },
      ]
    }
  }
}

export default ({data}: BlogPostProps) => {
  const {
    markdownRemark: post,
    allMarkdownRemark: {edges: related},
  } = data

  return (
    <ThemeProvider theme={theme}>
      <>
        <ResetCss />
        <Seo
          article
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          postPath={post.fields.slug}
          author={post.frontmatter.author}
          coverImage={post.frontmatter.coverImage}
        />
        <Navigation />

        <Box>
          <ArticleContainer>
            <BackA to="/">
              <Flex justifyContent="center" my="48px">
                <Flex alignItems="center">
                  <ArrowIcon direction="left" width="9px" height="17px" color="#929598" />
                  <Text ml="16px">Back</Text>
                </Flex>
              </Flex>
            </BackA>
            <Box maxWidth="747px" m="0 auto">
              <Title color={theme.colors.dark.anthracite} as="h1" textAlign="center">
                {post.frontmatter.title}
              </Title>
            </Box>
            <Flex justifyContent="center" m="48px 0 68px">
              <Author
                src={post.frontmatter.avatar}
                alt="Image"
                color="#58595b"
                name={post.frontmatter.author}
              />
              <Flex ml="48px" alignItems="center">
                <CalendarIcon height="12px" width="12px" color="#BCBEC0" />
                <Box ml="16px">
                  <FormattedDate
                    timestamp={Number(post.frontmatter.published)}
                    color="#58595b"
                    fontWeight={500}
                  />
                </Box>
              </Flex>
            </Flex>
          </ArticleContainer>
          <Container>
            <Dots />
          </Container>
          <ImgWrapper display={[null, null, null, null, null, 'none']}>
            <PreloadImage
              fullWidth
              imageOrientation="landscape"
              responsiveImages={[post.frontmatter.coverImage]}
              alt={post.frontmatter.title}
              ratio={[16, 9]}
              type="image"
            />
          </ImgWrapper>
          <ImgWrapper display={['none', null, null, null, null, 'block']}>
            <PreloadImage
              imageOrientation="landscape"
              responsiveImages={[post.frontmatter.coverImage]}
              alt={post.frontmatter.title}
              ratio={[16, 9]}
              type="backgroundImage"
              backgroundImageWidth="100%"
              backgroundImageHeight="639px"
            />
          </ImgWrapper>
        </Box>
        <ArticleContainer>
          <Box position="relative">
            <Grid gridTemplateColumns={[null, null, null, '0px auto']}>
              <ArticleSocialShare
                url={`${config.siteUrl}${post.fields.slug}`}
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                twitterTitle={post.frontmatter.title}
              />
              <div>
                <br />
                <StyledArticle
                  dangerouslySetInnerHTML={{
                    __html: post.html,
                  }}
                />
              </div>
            </Grid>
          </Box>
        </ArticleContainer>
        {related.length > 0 && (
          <Box background="#f7f7f7">
            <Container>
              <Box p={['80px 0', null, null, '120px 0 64px']}>
                <SectionTitleSubtitle title="You might like" subtitle="Related posts" />
              </Box>
              <Grid
                gridTemplateColumns={['1fr', null, null, '1fr 1fr 1fr']}
                gridGap={['16px 0', null, null, '0 16px']}
                pb={['80px', null, null, '120px']}
              >
                {related.map(edge => (
                  <Link to={edge.node.fields.slug} key={edge.node.fields.slug}>
                    <FeaturedPost
                      category={edge.node.frontmatter.tag}
                      title={edge.node.frontmatter.title}
                      dateTimestamp={Number(edge.node.frontmatter.published)}
                      authorImage={edge.node.frontmatter.avatar}
                      authorName={edge.node.frontmatter.author}
                      postImage={[edge.node.frontmatter.coverImage]}
                    />
                  </Link>
                ))}
              </Grid>
            </Container>
          </Box>
        )}
        <Subscribe />
        <Footer />
      </>
    </ThemeProvider>
  )
}

// @ts-ignore
export const pageQuery = graphql`
  query BlogPostByID($id: String!, $tag: String!, $title: String!) {
    markdownRemark(id: {eq: $id}) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        author
        avatar
        coverImage
        description
        published(formatString: "X")
        title
      }
    }
    allMarkdownRemark(limit: 3, filter: {frontmatter: {tag: {eq: $tag}, title: {ne: $title}}}) {
      edges {
        node {
          frontmatter {
            author
            avatar
            coverImage
            tag
            title
            published(formatString: "X")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
