export default {
  breakpoints: ['576px', '768px', '992px', '1200px', '1376px', '1600px'],
  space: [0, 4, 8, 16, 32, 56, 114, 256, 512],
  fonts: {
    primary: 'Montserrat, sans-serif',
    secondary: 'Open Sans, sans-serif',
  },
  colors: {
    dark: {
      charcoal: '#001217',
      anthracite: '#343132',
    },
    blue: {
      bluMan: '#037db1',
      losBlue: '#00aeef',
      sky: '#20c2ff',
      waveFoam: '#f0fafe',
    },
    grey: {
      primary: '#f7f7f7',
      ghost: '#e6e7e8',
      thunder: '#929598',
      pollutedConcrete: '#58595b',
      ukranianConcrete: '#808285',
      silverCloud: '#bcbec0',
    },
    green: {
      tropicalFrog: '#0ac39a',
      losGreen: '#0ADBAD',
      invisibleLime: '#f1fdfa',
    },
    red: {
      redPink: '#ee2f53',
    },
  },
}
