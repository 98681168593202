import React, {useReducer} from 'react'
import styled from 'styled-components'
import {Form, Field} from 'react-final-form'
import gql from 'graphql-tag'
import {ApolloConsumer} from 'react-apollo'
import {
  width,
  height,
  color,
  WidthProps,
  HeightProps,
  ColorProps,
  compose,
  GridTemplateColumnsProps,
  GridGapProps,
  gridTemplateColumns,
  gridGap,
} from 'styled-system'
import {Input, Error as ErrorComponent} from 'los-design-system/components/Form'
import Container from 'los-design-system/components/Container'
import Flex from 'los-design-system/components/Flex'
import Button from 'los-design-system/components/Button'
import handleGraphQlSubmissionErrors from 'los-design-system/utils/handleGraphQlSubmissionErrors'
import {SectionTitle, SmallText, Text} from 'los-design-system/components/Text'
import RecaptchaModal from 'src/components/RecaptchaModal'
import {composeValidators, emailValidation, requiredValidation} from './validation'

interface ReducerState {
  showRecaptcha: boolean
  email: string
  error: string
  success: boolean
  loading: boolean
}

interface OpenRecaptchaAction {
  type: 'openRecaptcha'
  payload: {
    email: string
  }
}

interface LoadingAction {
  type: 'loading'
}

interface SuccessAction {
  type: 'success'
}

interface ResetAction {
  type: 'reset'
}

interface ErrorAction {
  type: 'error'
  payload: {
    error: string
  }
}

const reducerInitialValue: ReducerState = {
  showRecaptcha: false,
  email: '',
  error: '',
  success: false,
  loading: false,
}

function reducer(
  state: ReducerState,
  action: OpenRecaptchaAction | LoadingAction | SuccessAction | ResetAction | ErrorAction,
) {
  switch (action.type) {
    case 'openRecaptcha':
      return {...state, ...action.payload, showRecaptcha: true}
    case 'loading':
      return {...state, showRecaptcha: false, loading: true}
    case 'success':
      return {...state, loading: false, error: '', success: true}
    case 'error':
      return {...state, ...action.payload, loading: false}
    case 'reset':
      return {...state, showRecaptcha: false, error: '', success: false, loading: false}
    default:
      throw new Error()
  }
}

interface BorderProps extends WidthProps, HeightProps, ColorProps {}

const Border = styled('div')<BorderProps>`
  ${compose(
    width,
    height,
    color,
  )}
`

const A = styled('a')<ColorProps>`
  ${color}
`

const SubscribeFormWrapper = styled('div')`
  margin: 0 auto;
  text-align: center;
  max-width: 500px;
`

const FormWrapper = styled('div')`
  margin-bottom: 34px;
  background: #ffffff;
  text-align: left;
  position: relative;
  padding: 16px;
`

const InputStyle = styled(Input)`
  padding-bottom: 0;

  .InputError {
    position: relative;
    margin-top: 5px;
    height: auto;
  }
`

interface FormStyleProps extends GridTemplateColumnsProps, GridGapProps {}
const FormStyle = styled('form')<FormStyleProps>`
  display: grid;

  ${compose(
    gridTemplateColumns,
    gridGap,
  )}
`

const ButtonStyle = styled(Button)`
  align-self: flex-start;
`

const ErrorStyle = styled(ErrorComponent)`
  position: relative;
  margin-top: 5px;
  height: auto;
`

const SUBSCRIBE_MUTATION = gql`
  mutation SubscribeMutation($email: String!, $recaptchaKey: String!) {
    subscribeCreateSubscription(email: $email, recaptcha_key: $recaptchaKey) {
      email
    }
  }
`

const Subscribe = () => {
  const [state, dispatch] = useReducer(reducer, reducerInitialValue)

  const emailAddonBefore = (
    <Text color="#b0b3b5" fontWeight={500}>
      @
    </Text>
  )

  return (
    <section>
      <Border width="100%" height="1px" backgroundColor="grey.ghost" />
      <Container>
        <Flex maxWidth="1040px" m="0 auto">
          <SectionTitle textAlign="center" m={['64px 0 32px', null, '120px 0 80px']}>
            Subscribe and get more insights how to easily advertise or how to start earning using
            your unused screens.
          </SectionTitle>
        </Flex>
        <SubscribeFormWrapper>
          <FormWrapper>
            <Form
              onSubmit={({email}: {email: string}) =>
                dispatch({type: 'openRecaptcha', payload: {email}})
              }
              render={({handleSubmit}) => (
                <FormStyle
                  onSubmit={handleSubmit}
                  gridGap="0 8px"
                  gridTemplateColumns={['1fr', null, '1fr auto']}
                >
                  <Field
                    addonBefore={emailAddonBefore}
                    component={InputStyle}
                    name="email"
                    placeholder="Work email"
                    validate={composeValidators(requiredValidation, emailValidation)}
                  />
                  <ButtonStyle
                    type="submit"
                    size="semi"
                    loading={state.loading}
                    text="GET STARTED"
                    success={state.success}
                    ariaLabel="GET STARTED"
                  />
                </FormStyle>
              )}
            />
            {state.error && <ErrorStyle error={state.error || ''} />}
          </FormWrapper>
        </SubscribeFormWrapper>
        <SmallText
          textAlign="center"
          m={['10px 0 62px', null, '10px 0 120px']}
          color="grey.silverCloud"
        >
          By signing up for LifeOnScreen, you agree to our{' '}
          <A color="grey.silverCloud" href="https://lifeonscreen.com/terms-and-conditions">
            terms of service
          </A>
          .
        </SmallText>
      </Container>
      {state.showRecaptcha && (
        <ApolloConsumer>
          {client => (
            <RecaptchaModal
              apiKey={process.env.RECAPTCHA_API_KEY}
              expiredCallback={() => dispatch({type: 'reset'})}
              callback={(value: string) => {
                dispatch({type: 'loading'})
                client
                  .mutate({
                    mutation: SUBSCRIBE_MUTATION,
                    variables: {
                      email: state.email,
                      recaptchaKey: value,
                    },
                  })
                  .then(() => {
                    dispatch({type: 'success'})

                    // TODO: add event
                  })
                  .catch(error => {
                    const e = handleGraphQlSubmissionErrors(error) || {}
                    dispatch({
                      type: 'error',
                      payload: {error: e.email},
                    })
                  })
              }}
            />
          )}
        </ApolloConsumer>
      )}
    </section>
  )
}

export default Subscribe
