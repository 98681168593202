import React, {useEffect} from 'react'
import canUseDOM from '../../utils/canUseDOM'

// Hooks
import useLoadScript from '../../hooks/useLoadScript'

// Constants
const ID = '_grecaptcha.element.id'
const CALLBACK_NAME = '_grecaptcha.data-callback'
const EXPIRED_CALLBACK_NAME = '_grecaptcha.data-expired-callback'

const removeChild = (elem: any) => elem && elem.parentNode && elem.parentNode.removeChild(elem)

interface RecaptchaProps {
  apiKey?: string
  callback(value?: string): void
  expiredCallback?(): void
  className?: string
}

export default function Recaptcha({
  callback,
  apiKey = '',
  expiredCallback = () => {},
  className = '',
}: RecaptchaProps) {
  const isScriptLoaded = useLoadScript('https://www.google.com/recaptcha/api.js?hl=en-US', {
    attrs: {id: ID},
  })
  useEffect(() => {
    if (canUseDOM()) {
      // eslint-disable-next-line
      const submit = handleSubmit
      // @ts-ignore
      window[CALLBACK_NAME] = submit
      // @ts-ignore
      window[EXPIRED_CALLBACK_NAME] = expiredCallback
    }

    return () => {
      if (canUseDOM()) {
        removeChild(document.getElementById(ID))
      }
    }
  }, [isScriptLoaded])

  function removeRecaptchaChallenge() {
    if (canUseDOM()) {
      const iframe: any = document.querySelector('[title="recaptcha challenge"]')

      if (
        iframe &&
        iframe.parentNode &&
        iframe.parentNode.tagName === 'DIV' &&
        iframe.parentNode.parentNode &&
        iframe.parentNode.parentNode.tagName === 'DIV' &&
        iframe.parentNode.parentNode.parentNode &&
        iframe.parentNode.parentNode.parentNode.tagName === 'BODY'
      ) {
        const element = iframe.parentNode.parentNode
        setTimeout(() => document.body.removeChild(element), 100)
      }
    }
  }

  function handleSubmit(value: string) {
    removeRecaptchaChallenge()
    callback(value)
  }

  return (
    <div
      className={`g-recaptcha ${className}`}
      data-sitekey={apiKey}
      data-callback={CALLBACK_NAME}
      data-expired-callback={EXPIRED_CALLBACK_NAME}
    />
  )
}
