import React from 'react'
import styled from 'styled-components'
import Modal from 'los-design-system/components/Modal'
import Recaptcha from '../Recaptcha'

const ModalStyle = styled(Modal)`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .rc-dialog-content {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

interface RecaptchaModalProps {
  apiKey?: string
  callback(value?: string): void
  expiredCallback?(): void
}

export default function RecaptchaModal({
  callback,
  apiKey = '',
  expiredCallback = () => {},
}: RecaptchaModalProps) {
  return (
    <ModalStyle titleComponent={null} onClose={() => {}} closeIconComponent={null} visible>
      <Recaptcha apiKey={apiKey} callback={callback} expiredCallback={expiredCallback} />
    </ModalStyle>
  )
}
