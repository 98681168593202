import {useState} from 'react'
import canUseDOM from '../utils/canUseDOM'

export function loadScript(
  scriptUrl: string,
  options: Record<string, any> = {},
  callback: () => void = () => {},
  stateCallback: (success: boolean) => any = success => success,
) {
  // 1. Async lazy load
  const head = document.head || document.getElementsByTagName('head')[0]
  const script = document.createElement('script')
  script.src = scriptUrl
  script.type = 'text/javascript'
  script.async = 'async' in options ? options.async : true
  script.defer = 'defer' in options ? options.defer : false

  if (options.attrs) {
    Object.keys(options.attrs).forEach(attrKey => {
      script.setAttribute(attrKey, options.attrs[attrKey])
    })
  }

  script.onload = () => {
    stateCallback(true)
    callback()
  }

  head.appendChild(script)
}

export default function useLoadScript(
  scriptUrl: string,
  options: Record<string, any> = {},
  callback: () => void = () => {},
) {
  const [isLoaded, setIsLoaded] = useState(false)

  if (canUseDOM() && !isLoaded) {
    loadScript(scriptUrl, options, callback, setIsLoaded)
  }

  return isLoaded
}
