export const requiredValidation = (value: any) => {
  if ((value && !Array.isArray(value)) || (Array.isArray(value) && value.length > 0)) {
    return undefined
  }

  return 'Required'
}

export const emailValidation = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce((error: any, validator: any) => error || validator(value), undefined)
